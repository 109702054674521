@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
*{
  margin:0px;
}
body{
  font-family: 'Roboto', sans-serif;
}
.bg-header{
  background-color: #333333;
  padding: 23px 0;
}
.flex-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bg-header img{
  max-width: 140px;
}
.width-container{
  max-width: 1290px;
  margin: 0 auto;
  position: relative;
}
ul{
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
}

li{
  padding:0 20px;
}

a{
  color: #fff;
}